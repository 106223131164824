<template>
  <div style="height: inherit">
    <transition mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
